import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './theme/globalStyle';
import theme from './theme/theme';
import App from './views/App/App';
import AuthenticatorContext from './context/AuthenticatorContext';
import FiltersContext from './context/FiltersContext';
import ActivitiesContext from './context/ActivitiesContext';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <AuthenticatorContext.Provider>
      <ActivitiesContext.Provider>
        <FiltersContext.Provider>
          <App />
        </FiltersContext.Provider>
      </ActivitiesContext.Provider>
    </AuthenticatorContext.Provider>
  </ThemeProvider>,
  document.getElementById('root'),
);
