import { useState, FocusEvent, MouseEvent } from 'react';
import { ReactComponent as ExpandPicto } from '../../assets/pictos/agrandir.svg';
import { DropdownProps } from './Dropdown.interface';
import DropdownStyle from './Dropdown.styled';
import Checkbox from '../Checkbox/Checkbox';

export default function Dropdown({
  className, name, items, select,
} : DropdownProps) {
  const [isOpened, setIsOpened] = useState(false);

  const expand = () => {
    setIsOpened(true);
  };

  const collapse = () => {
    setIsOpened(false);
  };

  const collapseIfClickOutside = (currentTarget: HTMLElement, relatedTarget: EventTarget) => {
    if (!currentTarget.contains(relatedTarget as HTMLElement)) {
      collapse();
    }
  };

  const selectItem = (event: MouseEvent<HTMLButtonElement>) => {
    const target = event.target as HTMLElement;
    const targetItem = target.closest('.dropdown-item') as HTMLElement;
    select?.call(targetItem, parseInt(targetItem.dataset.id));
  };

  return (
    <DropdownStyle
      className={className}
      tabIndex={0}
      aria-label={`${name} dropdown trigger`}
      onClick={() => expand()}
      onBlur={(event: FocusEvent<HTMLDivElement>) => {
        collapseIfClickOutside(event.currentTarget, event.relatedTarget);
      }}
    >
      <span className="dropdown-title">{ name }</span>
      {
        isOpened && (
        <button className="dropdown-list" type="button" onClick={(e) => selectItem(e)}>
          <div className="dropdown-list-container">
            {
              items.map((item) => (
                <span
                  className={['dropdown-item', item.selected && 'selected'].join(' ')}
                  key={item.itemData.id}
                  data-id={item.itemData.id}
                >
                  <Checkbox name={`${item.itemData.name} filter`} checked={item.selected} />
                  <span className="item-name">
                    { item.itemData.name }
                  </span>
                </span>
              ))
            }
          </div>
        </button>
        )
      }
      <button
        className={['dropdown-expand', isOpened && 'opened'].join(' ')}
        type="button"
        aria-label="Expand the dropdown button"
        onClick={(e) => {
          if (isOpened) {
            e.stopPropagation();
            collapse();
          }
        }}
      >
        <ExpandPicto />
      </button>
    </DropdownStyle>
  );
}
