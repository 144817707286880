import { CustomI18n } from '../../customI18n';
import { ActivityCardProps } from './ActivityCard.interface';
import { ActivityCardStyle, Level, PreStaffing } from './ActivityCard.styled';
import pictoLocation from '../../assets/pictos/location.svg';
import Remote from '../Remote/Remote';
import ActivitiesContext from '../../context/ActivitiesContext';
import FiltersContext from '../../context/FiltersContext';
import DetailLine from '../DetailLine/DetailLine';

export default function ActivityCard({ activityProjectTuple, click } : ActivityCardProps) {
  const t = CustomI18n.getTranslation;
  const { isPreStaffing } = ActivitiesContext.useContext();
  const { getLevelNameById } = FiltersContext.useContext();

  return (
    <ActivityCardStyle
      className={activityProjectTuple.activity.viewed ? 'viewed' : ''}
      aria-label={`Activity ${activityProjectTuple.activity.name} for ${activityProjectTuple.project.name}`}
      onClick={() => click(activityProjectTuple)}
    >
      <h3 className="inline-ellipsis">
        { activityProjectTuple.activity.name }
      </h3>
      <div className="card-line">
        <Level>{ getLevelNameById(activityProjectTuple.activity.level) }</Level>
        {
          isPreStaffing(activityProjectTuple.project) && <PreStaffing>{ t('prestaffing') }</PreStaffing>
        }
      </div>
      <DetailLine label={t('project')} text={activityProjectTuple.project.name} />
      <DetailLine label={t('customer')} text={activityProjectTuple.project.client.name} />
      <DetailLine picto={pictoLocation} title={t('location')} text={activityProjectTuple.project.location || t('unknown.default')} />
      <Remote remote={activityProjectTuple.project.remote} />
    </ActivityCardStyle>
  );
}
