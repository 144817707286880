import { useState } from 'react';

export default function useDebounce() {
  const [timer, setTimer] = useState(null);

  const debounce = (callback: () => any, ms: number) => {
    clearTimeout(timer);

    setTimer(setTimeout(() => {
      callback();
    }, ms));
  };

  return {
    debounce,
  };
}
