export const requestFromAPI = async <O extends {}>(
  method : StringMethodType,
  route : string,
  payload? : {},
) => {
  const url = `${process.env.REACT_APP_API_URI}/${route.trim()}`;
  const response = await fetch(url,
    {
      method,
      body: method === 'POST' && payload ? JSON.stringify(payload) : undefined,
      headers: new Headers({
        'content-type': 'application/json',
      }),
      credentials: 'include',
    });

  if (!response.ok) {
    throw { name: response.statusText, code: response.status } as FetchError;
  }

  try {
    return (await response.json())?.data as O;
  } catch {
    return {} as O;
  }
};

export type StringMethodType = 'GET' | 'POST' | 'DELETE';

export interface FetchError extends Error {
  code: number,
}
