import styled from 'styled-components';

export default styled.span`
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: stretch;
  overflow: hidden;
  height: 2.2rem;
  border: 1px solid ${({ theme }) => theme.color.secondary};
  border-right: 0;
  border-radius: 0.3rem 0.3rem 0.3rem 0.3rem;
  background-color: ${({ theme }) => theme.color.secondaryWhite};

  .tag-name {
    display: flex;
    flex-grow: 1;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    padding: 0 0.6rem 0 0.6rem;
    color: ${({ theme }) => theme.color.primary};
    font-size: ${({ theme }) => theme.fontSize.xxSmall};
  }

  .tag-remove {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    width: 2.2rem;
    height: 100%;
    padding: 0 0.5rem 0 0.5rem;
    border: none;
    background-color: ${({ theme }) => theme.color.secondary};
    cursor: pointer;
  }
`;
