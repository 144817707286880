import styled from 'styled-components';
import checkmarkPicto from '../../assets/pictos/checkmark.svg';

export default styled.input`
  all: unset;
  flex-shrink: 0;
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 1.2rem;
  border: 1px solid black;
  border-radius: 0.3rem;
  cursor: pointer;

  &:checked {
    border-color: ${({ theme }) => theme.color.secondary};
    background-color: ${({ theme }) => theme.color.secondary};
    background-image: url(${checkmarkPicto});
    background-position: center;
    background-size: 1.275rem;
    background-repeat: no-repeat;
  }
`;
