import { CustomI18n } from '../../customI18n';
import AppStyle from './App.styled';
import Button from '../../components/Button/Button';
import Header from '../../components/Header/Header';
import ActivityCardList from '../../components/ActivityCardList/ActivityCardList';
import AuthenticatorContext from '../../context/AuthenticatorContext';
import octoWhiteImg from '../../assets/pictos/logo-mini-white.svg';

export default function App() {
  const t = CustomI18n.getTranslation;

  const {
    user,
    loaded,
    loginWithGoogle,
  } = AuthenticatorContext.useContext();

  return (
    <AppStyle role="main">
      <Header />
      <div className="content">
        { loaded && !user && <Button text={t('login')} name={t('login')} icon={octoWhiteImg} kind="normal" click={() => loginWithGoogle()} /> }
        { loaded && user && <ActivityCardList /> }
      </div>
    </AppStyle>
  );
}
