import translationFR from './assets/locales/fr/translation.json';

type Translation = { [key: string]: any };

export namespace CustomI18n {
  const translations: Translation = {
    'fr-FR': translationFR,
  };

  let language = 'fr-FR';

  let languagePointer = translations[language];

  export function setLanguage(lang: string) {
    if (Object.prototype.hasOwnProperty.call(translations, lang)) {
      language = lang;
      languagePointer = translations[language];
    }
  }

  export const getTranslation = (index: string): string | undefined => {
    const structure = index.split('.');
    let translationElement = languagePointer;
    for (let i = 0; i <= structure.length; i += 1) {
      if (typeof translationElement === 'string') {
        return translationElement;
      }
      if (typeof translationElement === 'object') {
        translationElement = translationElement[structure[i]];
      }
    }
    return undefined;
  };
}
