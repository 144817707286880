import { TagProps } from './Tag.interface';
import TagStyled from './Tag.styled';
import { ReactComponent as ClosePicto } from '../../assets/pictos/close.svg';

export default function Tag({ className, name, click } : TagProps) {
  return (
    <TagStyled className={className}>
      <span className="tag-name">{ name }</span>
      <button className="tag-remove" type="button" aria-label={`Remove the ${name} tag`} onClick={() => click && click()}>
        <ClosePicto />
      </button>
    </TagStyled>
  );
}
