import { CustomI18n } from '../../../customI18n';
import HeaderControlsStyled from './HeaderControls.styled';
import FiltersContext from '../../../context/FiltersContext';
import Dropdown from '../../Dropdown/Dropdown';
import Checkbox from '../../Checkbox/Checkbox';

export default function HeaderControls() {
  const t = CustomI18n.getTranslation;

  const {
    roles,
    levels,
    sectors,
    selectedRoles,
    selectedLevels,
    selectedSectors,
    showPresales,
    showPrestaffing,
    showRemoteOnly,
    showPrefill,
    toggleSelectedRole,
    toggleSelectedLevel,
    toggleSelectedSector,
    toggleShowPresales,
    toggleShowPrestaffing,
    toggleShowRemoteOnly,
    toggleShowPrefill,
    isFilterInArray,
  } = FiltersContext.useContext();

  return (
    <HeaderControlsStyled>
      <div className="header-controls-dropdown">
        <Dropdown
          className="header-filter"
          name={t('roles')}
          items={roles.map((role) => ({
            itemData: role,
            selected: isFilterInArray(role.id, selectedRoles),
          }))}
          select={(itemId: number) => {
            toggleSelectedRole(itemId);
          }}
        />
        <Dropdown
          className="header-filter"
          name={t('levels')}
          items={levels.map((level) => ({
            itemData: level,
            selected: isFilterInArray(level.id, selectedLevels),
          }))}
          select={(itemId: number) => {
            toggleSelectedLevel(itemId);
          }}
        />
        <Dropdown
          className="header-filter"
          name={t('sectors')}
          items={sectors.map((sector) => ({
            itemData: sector,
            selected: isFilterInArray(sector.id, selectedSectors),
          }))}
          select={(itemId: number) => {
            toggleSelectedSector(itemId);
          }}
        />
      </div>
      <div className="header-controls-checkboxes">
        <div className="header-filter-conditional">
          <span className="header-filter-conditional-item">
            <Checkbox
              className="header-filter-conditional-item-checkbox"
              name="Show presales"
              checked={showPresales}
              change={() => {
                toggleShowPresales();
              }}
            />
            <label className="header-filter-conditional-item-label" htmlFor="Show presales">{ t('displayFilters.presales') }</label>
          </span>
          <span className="header-filter-conditional-item">
            <Checkbox
              className="header-filter-conditional-item-checkbox"
              name="Show prestaffing"
              checked={showPrestaffing}
              change={() => {
                toggleShowPrestaffing();
              }}
            />
            <label className="header-filter-conditional-item-label" htmlFor="Show prestaffing">{ t('displayFilters.prestaffing') }</label>
          </span>
        </div>
        <div className="header-filter-conditional">
          <span className="header-filter-conditional-item">
            <Checkbox
              className="header-filter-conditional-item-checkbox"
              name="Show remote only"
              checked={showRemoteOnly}
              change={() => {
                toggleShowRemoteOnly();
              }}
            />
            <label className="header-filter-conditional-item-label" htmlFor="Show remote only">{ t('displayFilters.remote') }</label>
          </span>
          <span className="header-filter-conditional-item">
            <Checkbox
              className="header-filter-conditional-item-checkbox"
              name="Show prefill"
              checked={showPrefill}
              change={() => {
                toggleShowPrefill();
              }}
            />
            <label className="header-filter-conditional-item-label" htmlFor="Show prefill">{ t('displayFilters.prefill') }</label>
          </span>
        </div>
      </div>
    </HeaderControlsStyled>
  );
}
