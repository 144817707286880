import { useState, useEffect } from 'react';
import { useGoogleLogin, useGoogleLogout } from 'react-google-login';
import { useGenerateContext } from '../utils/context';
import { requestFromAPI } from '../utils/api';

export const useAuthenticator = () => {
  const [user, setUser] = useState<IPerson>(null);
  const [loaded, setLoaded] = useState(false);

  const params = {
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    hostedDomain: 'octo.com',
    responseType: 'code',
    accessType: 'offline',
    cookiePolicy: 'none',
    uxMode: 'popup',
  };

  const fetchConnectedUser = async () => {
    let result : IPerson;
    setLoaded(false);

    try {
      result = await requestFromAPI<IPerson>('GET', 'api/v1/users/me');
      setUser(result);
    } catch (error) {
      result = error;
      setUser(null);
    }

    setLoaded(true);

    return result;
  };

  const connect = async (code? : string) => {
    let result : IPerson;
    setLoaded(false);

    try {
      result = await requestFromAPI<IPerson>('POST', 'auth/google_oauth2/callback', { code });
      setUser(result);
    } catch (error) {
      result = error;
      setUser(null);
    }

    setLoaded(true);

    return result;
  };

  const disconnect = async () => {
    let result : {};
    setLoaded(false);

    try {
      result = await requestFromAPI('DELETE', 'auth/logout');
    } catch (error) {
      result = error;
    }

    setUser(null);
    setLoaded(true);

    return result;
  };

  const { signIn } = useGoogleLogin({
    ...params,
    onSuccess: (response) => connect(response.code),
    onFailure: () => disconnect(),
  });

  const { signOut } = useGoogleLogout({
    ...params,
    onLogoutSuccess: () => disconnect(),
    onFailure: () => disconnect(),
  });

  useEffect(() => {
    if (!user) {
      fetchConnectedUser();
    }
  },
  []);

  return {
    user,
    loaded,
    loginWithGoogle: signIn,
    logoutWithGoogle: signOut,
    connect,
    disconnect,
    fetchConnectedUser,
  };
};

export default useGenerateContext(useAuthenticator);
