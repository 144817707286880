import styled from 'styled-components';
import bannerBackgroundFull from '../../assets/images/banner/banner.full.jpg';
import bannerBackground2560 from '../../assets/images/banner/banner.2560.jpg';
import bannerBackground1920 from '../../assets/images/banner/banner.1920.jpg';
import bannerBackground1200 from '../../assets/images/banner/banner.1200.jpg';
import bannerBackground768 from '../../assets/images/banner/banner.768.jpg';
import bannerBackground400 from '../../assets/images/banner/banner.400.jpg';
import bannerBackgroundFullWebp from '../../assets/images/banner/banner.full.webp';
import bannerBackground2560Webp from '../../assets/images/banner/banner.2560.webp';
import bannerBackground1920Webp from '../../assets/images/banner/banner.1920.webp';
import bannerBackground1200Webp from '../../assets/images/banner/banner.1200.webp';
import bannerBackground768Webp from '../../assets/images/banner/banner.768.webp';
import bannerBackground400Webp from '../../assets/images/banner/banner.400.webp';

export default styled.header`
  background-image: url(${bannerBackgroundFull});
  background-image: image-set(url(${bannerBackgroundFullWebp}) 1x);
  background-position-x: right;
  background-position-y: center;
  background-size: cover;

  .header-container {
    position: relative;
    display: flex;
    flex-flow: column;
    row-gap: 2.5rem;
    align-items: center;
    justify-content: flex-start;
    max-width: ${({ theme }) => theme.wrapper.maxWidth};
    margin: 0 auto;
    padding: 3.5rem 1.5rem;

    .logo {
      width: 27.5rem;
      height: 3.5rem;
    }

    .header-controls {
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
    }

    .header-user {
      position: absolute;
      top: 3.5rem;
      right: 1.5rem;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;

      button.header-user-actions {
        all: unset;

        img {
          width: 3.8rem;
          height: 3.8rem;
          border: 1px solid ${({ theme }) => theme.color.primaryLight};
          border-radius: 50%;
          background-color: ${({ theme }) => theme.color.primaryWhite};
          transition: opacity 0.2s ease-in-out;
        }

        img.hidden {
          opacity: 0;
        }

        + .dropdown-trigger {
          position: absolute;
          top: calc(50% + 1.6rem);
          right: -50%;
          z-index: 10;
          padding-top: 1rem;
          opacity: 0;
          transition: opacity 0.3s ease-in-out;

          .dropdown {
            width: 14rem;
            border: 1px solid ${({ theme }) => theme.color.primaryLight};
            border-radius: 0.5rem;
            background-color: ${({ theme }) => theme.color.primaryWhite};
            box-shadow: 2px 2px 10px 0 ${({ theme }) => theme.color.shadow};

            &::before {
              content: "";
              position: absolute;
              top: -0.5rem;
              left: 9.6rem;
              display: inline-block;
              border: 8px solid transparent;
              border-bottom-color: ${({ theme }) => theme.color.primaryLight};
            }

            &::after {
              content: "";
              position: absolute;
              top: -0.2rem;
              left: 9.7rem;
              display: inline-block;
              border: 7px solid transparent;
              border-bottom-color: ${({ theme }) => theme.color.primaryWhite};
            }
          }

          button {
            padding: 0.8rem 1rem 0.9rem 1rem;
            border: none;
            background: none;
            font-size: ${({ theme }) => theme.fontSize.xSmall};
            font-family: inherit;

            &:hover {
              color: ${({ theme }) => theme.color.secondary};
              cursor: pointer;
            }
          }

          &:hover {
            opacity: 1;
            visibility: visible;
          }
        }

        &:hover + .dropdown-trigger,
        &:focus + .dropdown-trigger {
          opacity: 1;
        }
      }
    }
  }

  ${({ theme }) => theme.device.desktopL} {
    background-image: url(${bannerBackground2560});
    background-image: image-set(url(${bannerBackground2560Webp}) 1x);
  }

  ${({ theme }) => theme.device.desktop} {
    background-image: url(${bannerBackground1920});
    background-image: image-set(url(${bannerBackground1920Webp}) 1x);
  }

  ${({ theme }) => theme.device.laptop} {
    background-image: url(${bannerBackground1200});
    background-image: image-set(url(${bannerBackground1200Webp}) 1x);
  }

  ${({ theme }) => theme.device.tablet} {
    background-image: url(${bannerBackground768});
    background-image: image-set(url(${bannerBackground768Webp}) 1x);

    .header-container {
      row-gap: 1.5rem;

      .header-user {
        position: relative;
        top: auto;
        right: auto;
      }
    }
  }

  ${({ theme }) => theme.device.mobile} {
    background-image: url(${bannerBackground400});
    background-image: image-set(url(${bannerBackground400Webp}) 1x);

    .header-container {
      a {
        align-self: center;
        justify-self: flex-start;
      }
    }
  }
`;
