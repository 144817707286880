import { useState, useEffect } from 'react';
import { CustomI18n } from '../customI18n';
import { useGenerateContext } from '../utils/context';
import { requestFromAPI } from '../utils/api';
import AuthenticatorContext from './AuthenticatorContext';
import ActivitiesContext from './ActivitiesContext';

export const useFilters = () => {
  const t = CustomI18n.getTranslation;

  const {
    user,
  } = AuthenticatorContext.useContext();

  const {
    staffableActivities,
    isPreStaffing,
  } = ActivitiesContext.useContext();

  const [isLoading, setIsLoading] = useState(false);
  const [levels, setLevels] = useState<ILevel[]>([]);
  const [roles, setRoles] = useState<IRole[]>([]);
  const [sectors, setSectors] = useState<ISector[]>([]);

  const [selectedRoles, setSelectedRoles] = useState<IRole[]>([]);
  const [selectedLevels, setSelectedLevels] = useState<ILevel[]>([]);
  const [selectedSectors, setSelectedSectors] = useState<ISector[]>([]);
  const [showPresales, setShowPresales] = useState(false);
  const [showPrestaffing, setShowPrestaffing] = useState(false);
  const [showRemoteOnly, setShowRemoteOnly] = useState(false);
  const [showPrefill, setShowPrefill] = useState(false);

  const [preventEffect, setPreventEffect] = useState(false);

  const fetchFilters = async () => {
    setIsLoading(true);
    const result = await requestFromAPI<IFilters>('GET', 'api/v1/filters');
    setLevels(result.levels);
    setRoles(result.roles);
    setSectors(result.sectors);
    setIsLoading(false);
  };

  const getLevelNameById = (id : number) => levels.find((level) => level.id === id)?.name || t('unknown.level');
  const getSectorNameById = (id : number) => sectors.find((sector) => sector.id === id)?.name || t('unknown.default');

  const isFilterInArray = (
    filterId: number,
    filterArray: IDNamePair[],
  ) => filterArray.some(({ id }) => id === filterId);

  const setDefaultSelectedRoles = () => {
    setSelectedRoles(roles.filter((role) => user.roles.includes(role.id)));
  };

  const removeAllSelectedRoles = () => {
    setSelectedRoles([]);
  };

  const toggleShowPresales = () => setShowPresales(!showPresales);

  const toggleShowPrestaffing = () => setShowPrestaffing(!showPrestaffing);

  const toggleShowRemoteOnly = () => setShowRemoteOnly(!showRemoteOnly);

  const toggleShowPrefill = () => setShowPrefill(!showPrefill);

  const toggleSelectedRole = (roleId : number) => {
    if (isFilterInArray(roleId, selectedRoles)) {
      setSelectedRoles(selectedRoles.filter(({ id }) => id !== roleId));
    } else {
      const role = roles.find(({ id }) => id === roleId);
      if (role) {
        setSelectedRoles(selectedRoles.concat([role]));
      }
    }

    if (showPrefill) {
      setPreventEffect(true);
      toggleShowPrefill();
    }
  };

  const toggleSelectedLevel = (levelId : number) => {
    if (isFilterInArray(levelId, selectedLevels)) {
      setSelectedLevels(selectedLevels.filter(({ id }) => id !== levelId));
    } else {
      const level = levels.find(({ id }) => id === levelId);
      if (level) {
        setSelectedLevels(selectedLevels.concat([level]));
      }
    }
  };

  const toggleSelectedSector = (sectorId : number) => {
    if (isFilterInArray(sectorId, selectedSectors)) {
      setSelectedSectors(selectedSectors.filter(({ id }) => id !== sectorId));
    } else {
      const sector = sectors.find(({ id }) => id === sectorId);
      if (sector) {
        setSelectedSectors(selectedSectors.concat([sector]));
      }
    }
  };

  const displayedActivities = staffableActivities.filter(({ activity, project }) => (
    selectedRoles.length === 0
      || selectedRoles.some((role) => role.id === activity.role)) && (
    selectedLevels.length === 0
      || selectedLevels.some((level) => level.id === activity.level)) && (
    selectedSectors.length === 0
      || selectedSectors.some((sector) => sector.id === project.client.sector.id)) && (
    showPresales
      || activity.name !== t('presale')) && (
    showPrestaffing
      || !isPreStaffing(project)) && (
    !showRemoteOnly
      || project.remote === 'possible'));

  useEffect(() => {
    if (!preventEffect) {
      if (user && showPrefill) {
        setDefaultSelectedRoles();
      } else {
        removeAllSelectedRoles();
      }
    } else {
      setPreventEffect(false);
    }
  },
  [showPrefill]);

  return {
    isLoading,
    fetchFilters,
    sectors,
    levels,
    roles,
    selectedSectors,
    selectedLevels,
    selectedRoles,
    showPresales,
    showPrestaffing,
    showRemoteOnly,
    showPrefill,
    displayedActivities,
    setDefaultSelectedRoles,
    removeAllSelectedRoles,
    toggleShowPresales,
    toggleShowPrestaffing,
    toggleShowRemoteOnly,
    toggleShowPrefill,
    toggleSelectedRole,
    toggleSelectedLevel,
    toggleSelectedSector,
    getLevelNameById,
    getSectorNameById,
    isFilterInArray,
  };
};

export default useGenerateContext(useFilters);
