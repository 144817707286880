import { CustomI18n } from '../../customI18n';
import { TeamProps } from './Team.interface';
import TeamStyle from './Team.styled';
import pictoInterrogation from '../../assets/pictos/interrogation.svg';

export default function Team({ activities } : TeamProps) {
  const t = CustomI18n.getTranslation;

  const peopleByActivity = (array: IActivity[]): {} => array.reduce((acc, current) => {
    acc[current.name] = acc[current.name] || [];
    acc[current.name].push(current.people);
    return acc;
  }, Object.create(null));

  const arrayPeopleByActivity: [string, []][] = Object.entries(peopleByActivity(activities));

  return (
    <TeamStyle>
      {
        arrayPeopleByActivity.map((activity) => (
          <div key={activity[0]}>
            <span>{ activity[0] }</span>
            <div className="people">
              {
                activity[1].map((activityPeople: IPerson[], index) => (
                  activityPeople.length === 0
                    ? (
                      <img
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        src={pictoInterrogation}
                        alt={t('missingPerson')}
                        title={t('missingPerson')}
                      />
                    )
                    : activityPeople.flat().map((person) => (
                      <a key={person.id} href={`https://askbob.octo.com/users/${person.nickname}`} target="_blank" rel="noreferrer">
                        <img
                          src={person.pictureUrl}
                          alt={`${person.firstName} ${person.lastName} - ${person.nickname}`}
                          title={`${person.firstName} ${person.lastName} - ${person.nickname}`}
                        />
                      </a>
                    ))
                ))
              }
            </div>
          </div>
        ))
      }
    </TeamStyle>
  );
}
