import IDetailLineProps from './DetailLine.interface';
import DetailLineStyle from './DetailLine.styled';

export default function DetailLine({
  text, label, picto, title,
} : IDetailLineProps) {
  return (
    <DetailLineStyle title={title}>
      { label && <span className="prefix">{ `${label} : ` }</span> }
      { picto && <img className="picto" width="20" src={picto} alt={text} /> }
      <span className="inline-ellipsis">{ text }</span>
    </DetailLineStyle>
  );
}
