import styled from 'styled-components';

export default styled.div`
display: flex;
flex-flow: column nowrap;
align-items: center;
justify-content: flex-start;
width: 100%;

.header-controls-dropdown {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;

  .header-filter:not(:first-child) {
    margin: 0 0 0 2.5rem;
  }

  ${({ theme }) => theme.device.tablet} {
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    .header-filter:not(:first-child) {
      margin: 1rem 0 0 0;
    }
  }
}

.header-controls-checkboxes {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2rem;

  .header-filter-conditional {
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 0 0 2rem;

    .header-filter-conditional-item {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      margin: 0.2rem 0 0.2rem 0;
      color: ${({ theme }) => theme.color.primaryWhite};

      .header-filter-conditional-item-checkbox {
        border-color: ${({ theme }) => theme.color.primaryWhite};
      }

      .header-filter-conditional-item-label {
        font-size: ${({ theme }) => theme.fontSize.xSmall};
        cursor: pointer;
        user-select: none;
      }
    }
  }
}

${({ theme }) => theme.device.tablet} {
  .header-controls-checkboxes {
    flex-flow: column nowrap;
    align-items: flex-start;
    margin-top: 2rem;

    .header-filter-conditional {
      width: 100%;
      padding: 0 2rem;
    }
  }
}

${({ theme }) => theme.device.mobile} {
  grid-column: 1 / span 3;
}
`;
