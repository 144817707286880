import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      width: 15rem;
      min-width: 15rem;
    }

    .people {
      display: flex;
      column-gap: 1rem;

      img {
        object-fit: scale-down;
        width: 2.7rem;
        height: 2.7rem;
        border: 1px solid ${({ theme }) => theme.color.primaryLighter};
        border-radius: 50% 50% 50% 50%;
      }
    }
  }
`;
