import styled from 'styled-components';
import Popup from 'reactjs-popup';

export default styled(Popup)`
  &-overlay {
    background-color: ${({ theme }) => theme.color.primaryTransparentMedium};
  }

  &-content {
    height: 57rem;
    margin: 2rem auto !important;
    border-radius: 3.5rem;

    .navigation {
      position: relative;
    }

    .modal {
      display: grid;
      grid-template-rows: 8rem auto;
      width: 80rem;
      height: 57rem;

      .activity {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        max-width: 100vw;
        padding: 0 2rem 0 4rem;
        border-radius: 3.5rem 3.5rem 0 0;
        background-color: ${({ theme }) => theme.color.white};
        column-gap: 1.5rem;

        .informations {
          display: grid;
          grid-template-rows: auto auto;
          row-gap: 0.1rem;

          .first-line {
            display: flex;
            flex-flow: row nowrap;
            align-items: baseline;

            .name {
              font-weight: 700;
              font-size: ${({ theme }) => theme.fontSize.huge};
            }

            .level {
              margin-left: 0.4rem;
              font-size: ${({ theme }) => theme.fontSize.xSmall};
            }
          }

          .second-line {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, max-content));
            font-size: ${({ theme }) => theme.fontSize.xSmall};
            column-gap: 1rem;

            .prestaffing {
              display: flex;
              flex-flow: row nowrap;
              align-items: center;
              justify-content: center;
              width: 9rem;
              padding: 0.3rem 1rem;
              border-radius: 0.5rem;
              background: ${({ theme }) => theme.color.primary};
              color: ${({ theme }) => theme.color.white};
              font-size: ${({ theme }) => theme.fontSize.xxSmall};
            }
          }
        }

        .controls {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-end;

          .close {
            all: unset;
            position: relative;
            width: 2.5rem;
            height: 1.5rem;
            padding: 0.5rem;
            opacity: 0.5;
            transition: all 0.3s ease-in-out;
          }

          .close:hover {
            opacity: 1;
            cursor: pointer;
          }

          .close::before,
          .close::after {
            content: ' ';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 0.2rem;
            height: 1.5rem;
            background-color: ${({ theme }) => theme.color.primary};
          }

          .close::before {
            transform: translate(-50%, -50%) rotate(45deg);
          }

          .close::after {
            transform: translate(-50%, -50%) rotate(-45deg);
          }
        }
      }

      .project {
        display: grid;
        grid-template-columns: 1fr 20rem;
        row-gap: 2rem;
        max-width: 100vw;
        padding: 2rem 4rem;
        border-radius: 0 0 3.5rem 3.5rem;
        background-color: ${({ theme }) => theme.color.primaryWhite};
        font-size: ${({ theme }) => theme.fontSize.xSmall};
        column-gap: 4rem;

        .main-section {
          display: flex;
          flex-flow: column nowrap;
          row-gap: 2rem;
          align-items: stretch;
          justify-content: start;
          overflow: hidden;

          .one-line {
            display: grid;
            grid-template-columns: minmax(0, max-content) 1fr;
            column-gap: 2rem;
          }

          .status > div {
            margin-top: 0.7rem;
          }

          .activity-team {
            flex-grow: 1;
            overflow: auto;
            max-height: 14rem;
          }
        }

        .side-section {
          display: flex;
          flex-flow: column nowrap;
          row-gap: 3rem;
          width: 20rem;

          .block {
            display: flex;
            flex-flow: column nowrap;
            row-gap: 0.7rem;

            &.contacts img {
              object-fit: cover;
              width: 3rem;
              height: 3rem;
              border-radius: 50% 50% 50% 50%;
            }

            b {
              margin-bottom: 0.5rem;
            }

            .link {
              display: flex;
              align-items: center;

              .picto {
                align-self: center;
                width: 1.7rem;
                min-width: 1.7rem;
                height: 1.7rem;
                min-height: 1.7rem;
                margin-right: 0.5rem;
              }
            }
          }
        }

        .description {
          grid-column: 1/3;
          overflow: auto;
          height: 9.5rem;
        }
      }

      .responsive-contact {
        display: none;
        grid-column: 1/3;
      }
    }
  }

  ${({ theme }) => theme.device.tablet} {
    &-overlay {
      overflow-y: auto;
      background-color: transparent;
    }

    &-content {
      width: 100%;
      height: initial;
      margin: 0 !important;

      .modal {
        grid-template-rows: 1fr 1fr;
        overflow-x: hidden;
        width: 100%;
        height: 100%;

        .activity {
          grid-template-columns: calc(100vw - 8rem) 0 2.5rem;
          padding: 1rem 2rem;
          border-radius: 0;

          .informations {
            row-gap: 0.5rem;

            .first-line {
              display: grid;
              grid-template-rows: repeat(2, auto);

              .name {
                white-space: pre-line;
              }

              .level {
                margin-left: 0;
              }
            }

            .second-line {
              display: grid;
              grid-template-rows: repeat(2, auto);
              grid-template-columns: auto;
            }
          }

          button.large {
            display: none;
          }
        }

        div[aria-label="contact-form"] {
          padding: 1rem 2rem;
        }

        .project {
          display: flex;
          flex-flow: column nowrap;
          align-items: stretch;
          justify-content: flex-start;
          padding: 3rem 2rem;
          border-radius: 0;

          .main-section {
            max-width: 100%;
            margin-right: 0;

            .details {
              row-gap: 1.7rem;
            }

            .one-line {
              display: initial;
            }

            .activity-team {
              max-height: initial;
            }
          }

          .side-section {
            grid-area: 2;
            row-gap: 2rem;
            width: 100%;
          }

          .description {
            overflow: initial;
            height: auto;
          }
        }

        .responsive-contact {
          position: fixed;
          bottom: 0;
          left: 0;
          z-index: 1000000000000000;
          display: flex;
          width: 100%;
          background-color: ${({ theme }) => theme.color.primaryWhite};

          button.large {
            margin: 1rem auto;
          }
        }
      }
    }
  }
`;
