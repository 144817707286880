import { CustomI18n } from '../../customI18n';
import { IRemoteProps } from './Remote.interface';
import pictoValid from '../../assets/pictos/valid.svg';
import pictoInvalid from '../../assets/pictos/invalid.svg';
import pictoInterrogation from '../../assets/pictos/interrogation.svg';
import DetailLine from '../DetailLine/DetailLine';

export default function Remote({ remote } : IRemoteProps) {
  const t = CustomI18n.getTranslation;

  switch (remote) {
    case 'possible':
      return (
        <DetailLine text={t('remote.possible')} picto={pictoValid} title={t('remote.title')} />
      );
    case 'impossible':
      return (
        <DetailLine text={t('remote.impossible')} picto={pictoInvalid} title={t('remote.title')} />
      );
    default:
      return (
        <DetailLine text={t('remote.unknown')} picto={pictoInterrogation} title={t('remote.title')} />
      );
  }
}
