import { CheckboxProps } from './Checkbox.interface';
import CheckboxStyle from './Checkbox.styled';

export default function Checkbox({
  className, name, checked, change,
}: CheckboxProps) {
  return (
    <CheckboxStyle
      className={className || ''}
      type="checkbox"
      id={name}
      checked={checked}
      aria-label={`${name} checkbox`}
      onChange={(event) => change && change(event.target.checked)}
    />
  );
}
