import styled from 'styled-components';

export default styled.button`
  display: table-cell;
  vertical-align: middle;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  height: 4rem;
  padding: 0.8rem 2rem;
  border: none;
  border-radius: 4.8rem;
  background: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.white};
  font-family: inherit;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: ${({ theme }) => theme.color.primaryHover};
  }

  img {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 1rem;
  }

  &.plain {
    height: 3rem;
    padding: 0 1.4rem;
    border: 0.2rem solid ${({ theme }) => theme.color.primary};
    background: unset;
    color: ${({ theme }) => theme.color.primary};
    font-weight: bold;

    &:hover {
      opacity: 0.9;
    }
  }

  &.large {
    height: 4rem;
  }
`;
