import styled from 'styled-components';

export default styled.div`
  .arrow {
    all: unset;
    position: absolute;
    top: 27rem;
    width: 0;
    height: 0;
    border-top: 1.5rem solid transparent;
    border-bottom: 1.5rem solid transparent;
    cursor: pointer;

    &.left {
      left: -3rem;
      border-right: 1.5rem solid ${({ theme }) => theme.color.white};
    }

    &.right {
      right: -3rem;
      border-left: 1.5rem solid ${({ theme }) => theme.color.white};
    }
  }

  ${({ theme }) => theme.device.tablet} {
    display: none;
  }
`;
