import styled from 'styled-components';

const ContactFormStyle = styled.div`
  padding: 0 3rem 2rem;
  background-color: ${({ theme }) => theme.color.white};

  textarea {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 1rem;
    padding: 1.5rem 2rem;
    border: none;
    border-radius: 0.8rem;
    background-color: ${({ theme }) => theme.color.primaryWhite};
    font-family: inherit;
    resize: none;

    &:focus {
      outline: none;
    }
  }
`;

export default ContactFormStyle;
