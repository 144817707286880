import styled from 'styled-components';

export default styled.div`
position: relative;
display: flex;
flex-flow: row nowrap;
align-items: center;
justify-content: space-between;
width: 24rem;
height: 4rem;
padding: 0 2rem;
border: 1px solid transparent;
border-radius: 2rem;
background-color: ${({ theme }) => theme.color.white};
font-size: ${({ theme }) => theme.fontSize.xSmall};
cursor: pointer;
user-select: none;

.dropdown-expand {
  z-index: 99;
  margin-right: -0.8rem;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;

  &.opened {
    z-index: 100;
  }

  &:not(.opened) {
    path {
      fill: ${({ theme }) => theme.color.primary};
    }
  }

  &:hover {
    border-radius: 50%;
    background-color: ${({ theme }) => theme.color.primaryLight};

    path {
      fill: white;
    }
  }
}

.dropdown-list {
  all: unset;
  position: absolute;
  top: -1px;
  left: -1px;
  z-index: 100;
  overflow: hidden;
  width: 100%;
  padding: 0;
  border: 1px solid ${({ theme }) => theme.color.primaryLight};
  border-radius: 2rem;
  background-color: ${({ theme }) => theme.color.primaryWhite};
  cursor: pointer;

  .dropdown-list-container {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 40rem;
    scrollbar-width: thin;
    overscroll-behavior: contain;

    .dropdown-item {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      height: 4rem;
      padding: 0 2rem;
      text-align: left;

      .item-name {
        display: -webkit-box;
        overflow: hidden;
        line-height: 1.8rem;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }

      &.active {
        background-color: ${({ theme }) => theme.color.primary};
        color: white;
      }

      &:hover {
        background-color: ${({ theme }) => theme.color.primaryLighter};
        color: inherit;

        &.active {
          background-color: ${({ theme }) => theme.color.primaryMedium};
          color: white;
        }
      }
    }

    &::-webkit-scrollbar {
      width: 1rem;
    }

    &::-webkit-scrollbar-track {
      margin: 1rem 0;
    }

    &::-webkit-scrollbar-thumb {
      border: 0.2rem solid ${({ theme }) => theme.color.primaryWhite};
      border-radius: 0.5rem;
      background-color: ${({ theme }) => theme.color.primaryMedium};

      &:active {
        background-color: ${({ theme }) => theme.color.primary};
      }
    }
  }
}
`;
