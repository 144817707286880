import { CustomI18n } from '../../customI18n';
import StepperStyle from './Stepper.styled';
import { StepperProps } from './Stepper.interface';
import ActivitiesContext from '../../context/ActivitiesContext';
import formatDate from '../../utils/date';

export default function Stepper({ statusHistory } : StepperProps) {
  const t = CustomI18n.getTranslation;

  const {
    steps,
    getCurrentStatusStep,
  } = ActivitiesContext.useContext();

  const currentIndex = getCurrentStatusStep(statusHistory);

  const projectSteps = steps.map((step) => (
    statusHistory.find((status) => status.status === step)
  ));

  return (
    <StepperStyle>
      {
        steps.map((step, index) => {
          const isEven = index % 2 === 0;
          const completed = (index < currentIndex) ? 'completed' : '';
          const current = (index === currentIndex) ? 'current' : '';
          const date = formatDate(projectSteps[index]?.date);

          return [
            isEven && (
              <div
                className={`step-label step-label-top ${completed} ${current}`}
                style={{ gridColumn: index + 1 }}
                key={step}
              >
                { t(`statusList.${step}`) }
                { date && <div className="date">{ formatDate(projectSteps[index]?.date) }</div> }
              </div>
            ),

            (
              <div
                className={`step-node
                  ${completed}
                  ${current}
                  ${(index === 0) ? 'start' : ''}
                  ${(index === steps.length - 1) ? 'end' : ''}`}
                style={{ gridColumn: index + 1 }}
                key={`${step}_node`}
              >
                <div className="step-node-bullet" />
              </div>
            ),

            !isEven && (
            <div
              className={`step-label step-label-bottom ${completed} ${current}`}
              style={{ gridColumn: index + 1 }}
              key={step}
            >
              { t(`statusList.${step}`) }
              { date && <div className="date">{ formatDate(projectSteps[index]?.date) }</div> }
            </div>
            ),
          ];
        })
      }
    </StepperStyle>
  );
}
