import { useState, useEffect, useRef } from 'react';
import { IContactFormProps } from './ContactForm.interface';
import ContactFormStyle from './ContactForm.styled';
import Button from '../Button/Button';
import { CustomI18n } from '../../customI18n';
import { requestFromAPI } from '../../utils/api';
import AuthenticatorContext from '../../context/AuthenticatorContext';
import useDebounce from '../../utils/useDebounce';

export default function ContactForm({
  recipients,
  activityDescription,
  projectId,
  activityId,
  onMessageSent,
} : IContactFormProps) {
  const t = CustomI18n.getTranslation;
  const { user } = AuthenticatorContext.useContext();
  const { debounce } = useDebounce();
  const [message, setMessage] = useState('');

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const sendForm = async () => {
    const body = {
      recipients: {
        businessContact: recipients,
        applicant: user.email,
      },
      data: {
        applicantNickname: user.nickname,
        projectId,
        activityId,
      },
      subject: `${user.firstName} ${user.lastName} ${t('interestedIn')} ${activityDescription}`,
      message,
    };

    await requestFromAPI('POST', 'api/v1/activities/send_application', body);
    setMessage('');
    onMessageSent();
  };

  useEffect(() => textAreaRef.current.focus(), []);

  return (
    <ContactFormStyle aria-label="contact-form">
      <textarea
        onChange={((event) => setMessage(event.target.value))}
        ref={textAreaRef}
        rows={4}
        placeholder={t('expressInterest')}
      />
      <Button text={t('send')} name={t('send')} kind="plain" click={() => debounce(sendForm, 500)} />
    </ContactFormStyle>
  );
}
