import { createGlobalStyle } from 'styled-components';
import CenturyGothic from '../assets/fonts/CenturyGothic-subset.min.ttf';
import CenturyGothicWoff from '../assets/fonts/CenturyGothic-subset.min.woff';
import CenturyGothicWoff2 from '../assets/fonts/CenturyGothic-subset.min.woff2';
import CenturyGothicBold from '../assets/fonts/CenturyGothic.bold-subset.min.ttf';
import CenturyGothicBoldWoff from '../assets/fonts/CenturyGothic.bold-subset.min.woff';
import CenturyGothicBoldWoff2 from '../assets/fonts/CenturyGothic.bold-subset.min.woff2';
import 'sanitize.css';

export default createGlobalStyle`
  &:root {
    overflow: hidden;
    font-size: 11px;
  }

  body {
    overflow: auto;
    height: 100vh;
  }

  body,
  button {
    color: ${({ theme }) => theme.color.primary};
    font-family: 'Century Gothic', 'Arial', sans-serif;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .inline-ellipsis {
    overflow: hidden;
    line-height: initial;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @font-face {
    font-weight: 400;
    font-style: normal;
    font-family: 'Century Gothic';
    src:
      local('Century Gothic'),
      local('CenturyGothic'),
      url(${CenturyGothicWoff2}) format('woff2'),
      url(${CenturyGothicWoff}) format('woff'),
      url(${CenturyGothic}) format('truetype');
    font-display: fallback;
  }

  @font-face {
    font-weight: bold;
    font-style: normal;
    font-family: 'Century Gothic';
    src:
      local('Century Gothic Bold'),
      local('CenturyGothicBold'),
      url(${CenturyGothicBoldWoff2}) format('woff2'),
      url(${CenturyGothicBoldWoff}) format('woff'),
      url(${CenturyGothicBold}) format('truetype');
    font-display: fallback;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => theme.color.secondary};
    }
  }

  ::-webkit-scrollbar {
    width: 0.8rem;
    height: 0.8rem;
  }

  ::-webkit-scrollbar-track {
    border-radius: 1rem;
    background: ${({ theme }) => theme.color.primaryWhite};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background: rgba(0, 0, 0, 0.2);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
  }

  ::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.9);
  }
`;
