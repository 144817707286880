import { useState } from 'react';
import { CustomI18n } from '../../customI18n';
import HeaderStyled from './Header.styled';
import AuthenticatorContext from '../../context/AuthenticatorContext';
import HeaderControls from './HeaderControls/HeaderControls';
import Logo from '../../assets/images/jobboard-logo.secondary.svg';

export default function Header() {
  const t = CustomI18n.getTranslation;

  const [isLoaded, setIsLoaded] = useState(false);
  const { logoutWithGoogle, user } = AuthenticatorContext.useContext();

  return (
    <HeaderStyled role="banner">
      <div className="header-container">
        <a href="/">
          <img className="logo" src={Logo} alt="JOBBOARD logo" />
        </a>
        {
          user && (
          <div className="header-user">
            <button className="header-user-actions" type="button" aria-haspopup="true">
              <img
                className={!isLoaded ? 'hidden' : ''}
                src={user.pictureUrl}
                alt={`${user.firstName} ${user.lastName} - ${user.nickname}`}
                onLoad={() => setIsLoaded(true)}
              />
            </button>
            <div className="dropdown-trigger">
              <div className="dropdown">
                <button
                  type="button"
                  name={t('logout')}
                  aria-label="Disconnect"
                  onClick={() => logoutWithGoogle()}
                >
                  { t('logout') }
                </button>
              </div>
            </div>
          </div>
          )
        }
        {
          user && <HeaderControls />
        }
      </div>
    </HeaderStyled>
  );
}
