import { CustomI18n } from '../../customI18n';
import useKeyboardNavigation from '../../hooks/useNavigation/useNavigation';
import { IModalActivityNavigationProps } from './ModalActivityNavigation.interface';
import ModalActivityNavigationStyled from './ModalActivityNavigation.styled';

export default function ModalActivityNavigation(
  {
    isFirstElement, isLastElement, next, prev,
  } : IModalActivityNavigationProps,
) {
  const t = CustomI18n.getTranslation;

  const handleKeyboardNavigation = (
    e: React.KeyboardEvent,
  ) => useKeyboardNavigation(e.key, isFirstElement, isLastElement, prev, next);

  return (
    <ModalActivityNavigationStyled role="navigation" onKeyDown={handleKeyboardNavigation}>
      { !isFirstElement && <button type="button" aria-label={t('previousActivity')} className="arrow left" onClick={prev} /> }
      { !isLastElement && <button type="button" aria-label={t('nextActivity')} className="arrow right" onClick={next} /> }
    </ModalActivityNavigationStyled>
  );
}
