/* eslint-disable react/no-danger */
import { useState } from 'react';
import { CustomI18n } from '../../customI18n';
import { IModalActivityProps } from './ModalActivity.interface';
import ModalActivityStyled from './ModalActivity.styled';
import FiltersContext from '../../context/FiltersContext';
import ActivitiesContext from '../../context/ActivitiesContext';
import Button from '../Button/Button';
import Stepper from '../Stepper/Stepper';
import ContactForm from '../ContactForm/ContactForm';
import pictoTheatre from '../../assets/pictos/theatre.svg';
import pictoLocation from '../../assets/pictos/location.svg';
import pictoCalendar from '../../assets/pictos/calendar.svg';
import pictoOctopod from '../../assets/pictos/octopod.svg';
import pictoDrive from '../../assets/pictos/drive.svg';
import DetailLine from '../DetailLine/DetailLine';
import Remote from '../Remote/Remote';
import Team from '../Team/Team';
import ModalActivityNavigation from '../ModalActivityNavigation/ModalActivityNavigation';
import formatDate from '../../utils/date';

export default function ModalActivity({
  activityProjectTuple,
  isFirstElement,
  isLastElement,
  showContactForm,
  showContactFormToggle,
  closeCallback,
  next,
  prev,
} : IModalActivityProps) {
  const t = CustomI18n.getTranslation;
  const [appliedActivities, setAppliedActivities] = useState([]);
  const { getLevelNameById, getSectorNameById } = FiltersContext.useContext();
  const { isPreStaffing } = ActivitiesContext.useContext();

  const formatedAmount = (amount: number | string) => amount?.toLocaleString('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const thi = () => (activityProjectTuple.project.thi ? `${activityProjectTuple.project.thi}/5` : t('unknown.default'));

  const closeModal = () => {
    closeCallback();
  };

  const onMessageSent = () => {
    setAppliedActivities([...appliedActivities, {
      id: activityProjectTuple.activity.id,
      sentAt: new Date(Date.now()),
    }]);

    showContactFormToggle();
  };

  const contactButton = () => {
    const activityId = activityProjectTuple.activity.id;
    let text : string = `${t('contactButton')} ${activityProjectTuple.project.contacts.mission?.firstName || activityProjectTuple.project.contacts.business.firstName}`;
    let kind : 'compact' | 'plain' = 'compact';

    if (appliedActivities.map((activity) => activity.id).includes(activityId)) {
      text = `${t('emailSentAt')} ${formatDate(appliedActivities.find((activity) => activity.id === activityId).sentAt)}`;
      kind = 'plain';
    } else if (activityProjectTuple.activity.applied) {
      text = `${t('emailSentAt')} ${formatDate(activityProjectTuple.activity.appliedAt)}`;
      kind = 'plain';
    }

    return (
      <Button
        className="large"
        text={text}
        name={`${t('contactButton')} ${text}`}
        kind={kind}
        click={() => showContactFormToggle()}
      />
    );
  };

  const contactPerson = (person: IPerson, role: string) => (
    <a href={`https://askbob.octo.com/users/${person.nickname}`} target="_blank" rel="noreferrer" title={`${person.firstName} ${person.lastName} - ${person.nickname}`}>
      <DetailLine text={`${person.nickname} (${t(role)})`} picto={person.pictureUrl} />
    </a>
  );

  const externalLink = (url: string, picto: string, anchor: string) => (
    <div className="link">
      <img className="picto" src={picto} alt="drive" />
      <a href={url} target="_blank" rel="noreferrer">{ t(anchor) }</a>
    </div>
  );

  return (
    <ModalActivityStyled
      modal
      nested
      open={Boolean(activityProjectTuple)}
      onClose={closeModal}
      lockScroll
      closeOnEscape
      closeOnDocumentClick
    >
      { (close: any) => (
        <>
          <ModalActivityNavigation
            isFirstElement={isFirstElement}
            isLastElement={isLastElement}
            next={next}
            prev={prev}
          />
          <div
            className="modal"
            aria-label={`Modal of activity ${activityProjectTuple.activity.id}`}
          >
            <div className="activity">
              <div className="informations">
                <div className="first-line">
                  <div className="inline-ellipsis name">{ activityProjectTuple.activity.name }</div>
                  <div className="inline-ellipsis level">{ `(${getLevelNameById(activityProjectTuple.activity.level)})` }</div>
                </div>
                <div className="second-line">
                  { isPreStaffing(activityProjectTuple.project) && <div className="prestaffing">{ t('prestaffing') }</div> }
                  <DetailLine text={`${activityProjectTuple.activity.nbDays} ${t('days')}`} label={t('plannedDays')} />
                </div>
              </div>
              <div className="controls">
                { contactButton() }
                <button type="button" className="close" name={t('close')} aria-label={t('close')} onClick={close} />
              </div>
            </div>
            {
              showContactForm && (
                <ContactForm
                  recipients={
                    activityProjectTuple.project.contacts.mission?.email
                      || activityProjectTuple.project.contacts.business.email
                  }
                  activityDescription={`${activityProjectTuple.activity.name} - ${activityProjectTuple.project.client.name.toUpperCase()} - ${activityProjectTuple.project.name}`}
                  projectId={activityProjectTuple.project.id}
                  activityId={activityProjectTuple.activity.id}
                  onMessageSent={onMessageSent}
                />
              )
            }
            <div className="project">
              <div className="main-section">
                <div className="details">
                  <DetailLine text={activityProjectTuple.project.name} label={t('project')} />
                  <DetailLine text={activityProjectTuple.project.client.name} label={t('customer')} />
                  <div className="one-line">
                    <DetailLine text={t(`projectTypeList.${activityProjectTuple.project.type}`)} label={t('projectType')} />
                    <DetailLine text={getSectorNameById(activityProjectTuple.project.client.sector.id)} label={t('sector')} />
                  </div>
                  <DetailLine text={formatedAmount(activityProjectTuple.project.amount)} label={t('amount')} />
                </div>
                <div className="status">
                  <b>{ t('status') }</b>
                  <Stepper statusHistory={activityProjectTuple.project.statusHistory} />
                </div>
                <div className="activity-team">
                  <b>{ t('team') }</b>
                  <Team activities={activityProjectTuple.project.activities} />
                </div>
              </div>
              <div className="side-section">
                <div className="block contacts">
                  <b>{ t('contacts') }</b>
                  { activityProjectTuple.project.contacts.mission && contactPerson(activityProjectTuple.project.contacts.mission, 'dimi') }
                  { contactPerson(activityProjectTuple.project.contacts.business, 'bizdev') }
                </div>
                <div className="block">
                  <b>{ t('practicalInfos') }</b>
                  <DetailLine text={`${formatDate(activityProjectTuple.project.startDate)} ${t('to')} ${formatDate(activityProjectTuple.project.endDate)}`} picto={pictoCalendar} />
                  <DetailLine text={`THI Moyen : ${thi()}`} picto={pictoTheatre} />
                  <DetailLine text={activityProjectTuple.project.location || t('unknown.default')} picto={pictoLocation} />
                  <Remote remote={activityProjectTuple.project.remote} />
                </div>
                <div className="block">
                  <b>{ t('links') }</b>
                  { externalLink(`https://octopod.octo.com/projects/${activityProjectTuple.project.id}`, pictoOctopod, 'octopod') }
                  { externalLink(activityProjectTuple.project.gdriveUrl, pictoDrive, 'drive') }
                </div>
              </div>
              <div className="description" dangerouslySetInnerHTML={{ __html: `<b>${t('description')} :</b> ${activityProjectTuple.project.description}` }} />
              <div className="responsive-contact">
                { contactButton() }
              </div>
            </div>
          </div>
        </>
      ) }
    </ModalActivityStyled>
  );
}
