import styled from 'styled-components';

export default styled.span`
  &::after {
    content: '';
    position: absolute;
    top: calc(50% - 2rem);
    left: calc(50% - 2rem);
    box-sizing: border-box;
    width: 4rem;
    height: 4rem;
    border-top: 4px solid ${({ theme }) => theme.color.secondary};
    border-right: 4px solid rgba(255, 255, 255, 0);
    border-bottom: 4px solid ${({ theme }) => theme.color.secondary};
    border-left: 4px solid ${({ theme }) => theme.color.secondary};
    border-radius: 50%;
    animation: spinner 0.8s linear infinite;
  }

  @keyframes spinner {
    to { transform: rotate(360deg); }
  }
`;
