import { useState, useEffect } from 'react';
import { CustomI18n } from '../../customI18n';
import ActivityCardListStyle from './ActivityCardList.styled';
import ActivityCard from '../ActivityCard/ActivityCard';
import ActivitiesContext from '../../context/ActivitiesContext';
import FiltersContext from '../../context/FiltersContext';
import Loader from '../Loader/Loader';
import Button from '../Button/Button';
import Tag from '../Tag/Tag';
import ModalActivity from '../ModalActivity/ModalActivity';

export default function ActivityCardList() {
  const RESULTS_PER_PAGE = Number(process.env.REACT_APP_RESULTS_PER_PAGE);
  const t = CustomI18n.getTranslation;

  const {
    isLoading,
    getStaffableActivities,
    setActivityAsViewed,
  } = ActivitiesContext.useContext();

  const {
    fetchFilters,
    selectedRoles,
    selectedLevels,
    selectedSectors,
    displayedActivities,
    showPrestaffing,
    showPrefill,
    toggleSelectedRole,
    toggleSelectedLevel,
    toggleSelectedSector,
    toggleShowPrestaffing,
    toggleShowPrefill,
  } = FiltersContext.useContext();

  const [showContactForm, setShowContactForm] = useState(false);
  const [showMore, setShowMore] = useState(displayedActivities.length > RESULTS_PER_PAGE);
  const [index, setIndex] = useState(RESULTS_PER_PAGE);
  const [
    activityProjectTupleSelectedIndex,
    setActivityProjectTupleSelectedIndex,
  ] = useState<number>(null);

  const load = async () => {
    await fetchFilters();
    if (!showPrestaffing) {
      toggleShowPrestaffing();
    }
    if (!showPrefill) {
      toggleShowPrefill();
    }
    await getStaffableActivities();
  };

  const loadMore = () => {
    const newIndex = index + RESULTS_PER_PAGE;
    const newShowMore = newIndex < displayedActivities.length;
    setShowMore(newShowMore);
    setIndex(newIndex);
  };

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    setShowMore(index < displayedActivities.length);
  }, [displayedActivities]);

  useEffect(() => {
    if (activityProjectTupleSelectedIndex != null) {
      setActivityAsViewed(displayedActivities[activityProjectTupleSelectedIndex].activity);
    }
  },
  [activityProjectTupleSelectedIndex]);

  return (
    <ActivityCardListStyle>
      { (selectedRoles.length > 0 || selectedLevels.length > 0 || selectedSectors.length > 0) && (
        <div className="activity-filters">
          <span className="activity-filters-title">{ t('selectedFilters') }</span>
          {
            selectedRoles
              .sort((roleA, roleB) => roleA.name.localeCompare(roleB.name))
              .map((selectedRole) => (
                <Tag
                  key={selectedRole.id}
                  className="activity-tag"
                  name={selectedRole.name === t('other') ? t('otherRoles') : selectedRole.name}
                  click={() => toggleSelectedRole(selectedRole.id)}
                />
              ))
          }
          {
            selectedLevels
              .sort((levelA, levelB) => levelA.name.localeCompare(levelB.name))
              .map((selectedLevel) => (
                <Tag
                  key={selectedLevel.id}
                  className="activity-tag"
                  name={selectedLevel.name}
                  click={() => {
                    toggleSelectedLevel(selectedLevel.id);
                  }}
                />
              ))
          }
          {
            selectedSectors
              .sort((sectorA, sectorB) => sectorA.name.localeCompare(sectorB.name))
              .map((selectedSector) => (
                <Tag
                  key={selectedSector.id}
                  className="activity-tag"
                  name={selectedSector.name}
                  click={() => {
                    toggleSelectedSector(selectedSector.id);
                  }}
                />
              ))
          }
        </div>
      ) }
      { isLoading && <Loader /> }
      { !isLoading
      && (
      <>
        <div className="activity-list">
          {
            displayedActivities.slice(0, index).map(({ activity }, i) => (
              <ActivityCard
                activityProjectTuple={displayedActivities[i]}
                key={activity.id}
                click={() => {
                  setActivityProjectTupleSelectedIndex(i);
                }}
              />
            ))
          }
          {
            showMore && (
              <Button className="activity-list-showmore" name={t('showMore')} text={t('showMore')} kind="normal" click={loadMore} />
            )
          }
        </div>
        {
          activityProjectTupleSelectedIndex !== null && (
            <ModalActivity
              activityProjectTuple={displayedActivities[activityProjectTupleSelectedIndex]}
              isFirstElement={activityProjectTupleSelectedIndex === 0}
              isLastElement={activityProjectTupleSelectedIndex === displayedActivities.length - 1}
              showContactForm={showContactForm}
              showContactFormToggle={() => setShowContactForm(!showContactForm)}
              closeCallback={() => {
                setActivityProjectTupleSelectedIndex(null);
                setShowContactForm(false);
              }}
              next={() => {
                setActivityProjectTupleSelectedIndex(activityProjectTupleSelectedIndex + 1);
                setShowContactForm(false);
              }}
              prev={() => {
                setActivityProjectTupleSelectedIndex(activityProjectTupleSelectedIndex - 1);
                setShowContactForm(false);
              }}
            />
          )
        }
      </>
      ) }
    </ActivityCardListStyle>
  );
}
