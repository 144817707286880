import styled from 'styled-components';

export default styled.div`
  display: grid;
  grid-template-rows: auto 20px auto;
  font-size: ${({ theme }) => theme.fontSize.xxSmall};

  .step-node {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    grid-row: 2;
    align-items: center;
    justify-content: center;
    width: 100%;

    &::before,
    &::after {
      content: '';
      position: absolute;
      height: 0.2rem;
      background-color: ${({ theme }) => theme.color.primaryLight};
    }

    &:not(.start)::before {
      right: 50%;
      left: 0;
    }

    &:not(.end)::after {
      right: 0;
      left: 50%;
    }

    .step-node-bullet {
      z-index: 1;
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 50% 50% 50% 50%;
      background-color: ${({ theme }) => theme.color.primaryLight};
    }

    &.current {
      .step-node-bullet {
        width: 1rem;
        height: 1rem;
        background-color: ${({ theme }) => theme.color.secondaryMedium};
      }
    }
  }

  .step-label {
    display: flex;
    flex-direction: column;
    justify-self: center;
    width: 120%;
    color: ${({ theme }) => theme.color.primaryLight};
    font-size: 1.2rem;
    text-align: center;
    white-space: nowrap;

    .date {
      margin-top: 0.1rem;
      font-size: ${({ theme }) => theme.fontSize.tiny};
    }

    &.step-label-top {
      grid-row: 1;
      align-self: end;
    }

    &.step-label-bottom {
      grid-row: 3;
      align-self: start;
    }

    &.current {
      color: ${({ theme }) => theme.color.secondaryMedium};
    }

    &.completed {
      color: ${({ theme }) => theme.color.primary};
    }
  }

  .step-node.completed .step-node-bullet,
  .step-node.completed::after,
  .step-node.completed::before,
  .step-node.current::before {
    background-color: ${({ theme }) => theme.color.primary};
  }

  ${({ theme }) => theme.device.tablet} {
    .step-label {
      font-size: 1.2rem;
      white-space: inherit;
    }
  }
`;
