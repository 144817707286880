import styled from 'styled-components';

const ActivityCardListStyle = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: stretch;

  .activity-filters {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    padding: 0 0 2.5rem 0;

    .activity-filters-title {
      margin: 0 0 1rem 1rem;
      font-size: ${({ theme }) => theme.fontSize.medium};
    }

    .activity-tag {
      margin: 0 0 1rem 1rem;
    }
  }

  .activity-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 33.5rem));
    grid-gap: 2.5rem;
    justify-content: center;
  }

  button.activity-list-showmore {
    grid-column: 1 / -1;
    place-self: center;
  }
`;

export default ActivityCardListStyle;
