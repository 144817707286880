import styled from 'styled-components';

const ActivityCardStyle = styled.button`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  height: 21rem;
  padding: 2rem;
  border: 1px solid ${({ theme }) => theme.color.primaryLighter};
  border-radius: 2rem;
  background: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.primary};
  font-size: ${({ theme }) => theme.fontSize.xSmall};
  line-height: initial;
  text-align: left;
  user-select: text;
  transition: box-shadow 0.3s 0s ease-in-out;

  &:hover {
    box-shadow: 2px 2px 10px 0 ${({ theme }) => theme.color.shadow};
    cursor: pointer;
  }

  &.viewed {
    opacity: 0.7;
  }

  > * {
    display: flex;
    align-items: center;
    width: auto;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h3 {
    display: block;
    width: 100%;
    margin: 0 0 0.7rem 0;
    color: ${({ theme }) => theme.color.primaryDark};
    font-weight: bold;
    font-size: ${({ theme }) => theme.fontSize.large};
  }

  .card-line {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;

    > :not(:first-child) {
      margin-left: 0.8rem;
    }
  }

  ${({ theme }) => theme.device.tablet} {
    .inline-ellipsis {
      white-space: nowrap !important;
    }
  }
`;

const Level = styled.div`
  width: auto;
  padding: 0.3rem 1rem;
  border: 1px solid ${({ theme }) => theme.color.secondary};
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.color.primary};
  font-size: ${({ theme }) => theme.fontSize.xxSmall};
`;

const PreStaffing = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  padding: 0.3rem 1rem;
  border: 1px solid ${({ theme }) => theme.color.primary};
  border-radius: 0.5rem;
  background: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => theme.fontSize.xxSmall};
`;

export { ActivityCardStyle, Level, PreStaffing };
