import { useState } from 'react';
import { useGenerateContext } from '../utils/context';
import { requestFromAPI } from '../utils/api';

export const useActivities = () => {
  const steps = ['lead', 'proposal_in_progress', 'proposal_sent', 'mission_accepted', 'mission_signed', 'mission_done'];

  const [staffableActivities, setStaffableActivities] = useState<IActivityProjectTuple[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchProjects = async () => {
    try {
      const result = await requestFromAPI<IProject[]>('GET', 'api/v1/projects');
      return result;
    } catch (error) {
      return [];
    }
  };

  const getStaffableActivities = async () => {
    const activities: IActivityProjectTuple[] = [];

    setIsLoading(true);
    const projects = await fetchProjects();

    projects.forEach((project, projectIndex) => {
      project.activities.forEach((activity, activityIndex) => {
        if (activity.staffingNeeded) {
          activities.push({
            activity: projects[projectIndex].activities[activityIndex],
            project: projects[projectIndex],
          });
        }
      });
    });

    setStaffableActivities(activities);
    setIsLoading(false);
  };

  const getCurrentStatusStep = (statusHistory: IStatus[]) => Math.max(
    ...statusHistory.map((status) => steps.indexOf(status.status)),
  );

  const isPreStaffing = (project: IProject) => {
    const currentStatusStepID = getCurrentStatusStep(project.statusHistory);
    const preStaffingStepID = steps.indexOf('mission_accepted');
    return currentStatusStepID < preStaffingStepID;
  };

  const setActivityAsViewed = async (activity: IActivity) => {
    await requestFromAPI('POST', 'api/v1/activities/view', { id: activity.id });
    setStaffableActivities(staffableActivities.map((staffableActivity) => {
      const result = staffableActivity;
      if (result.activity.id === activity.id) {
        result.activity.viewed = true;
      }

      return result;
    }));
  };

  return {
    isLoading,
    steps,
    staffableActivities,
    getStaffableActivities,
    getCurrentStatusStep,
    isPreStaffing,
    setActivityAsViewed,
  };
};

export default useGenerateContext(useActivities);
