import styled from 'styled-components';

export default styled.div`
  display: flex;
  align-items: baseline;
  max-width: 100%;

  .picto {
    align-self: center;
    width: 1.7rem;
    min-width: 1.7rem;
    height: 1.7rem;
    min-height: 1.7rem;
    margin-right: 0.5rem;
    user-select: none;
  }

  .prefix {
    flex-shrink: 0;
    margin-right: 0.5rem;
    font-weight: 900;
  }

  ${({ theme }) => theme.device.tablet} {
    .inline-ellipsis {
      white-space: normal;
    }
  }
`;
