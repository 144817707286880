import { ButtonProps } from './Button.interface';
import ButtonStyle from './Button.styled';

export default function Button({
  className, name, text, icon, kind, click,
} : ButtonProps) {
  return (
    <ButtonStyle
      className={[kind, className].join(' ').trim()}
      type="button"
      name={name}
      onClick={() => click()}
    >
      {
        icon
        && (
          <img
            src={icon}
            alt={`${name} icon`}
          />
        )
      }
      { text }
    </ButtonStyle>
  );
}
