import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: flex-start;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.color.primaryWhite};
  color: ${({ theme }) => theme.color.primary};
  text-align: center;

  .content {
    position: relative;
    flex-grow: 1;
    width: 100%;
    max-width: ${({ theme }) => theme.wrapper.maxWidth};
    margin: 0 auto;
    padding: 3.5rem 1.5rem;

    > button {
      margin: 0 auto;
    }
  }
`;
