const useKeyboardNavigation = (
  pressedKey: string,
  isFirstElement: boolean,
  isLastElement: boolean,
  prev: () => void,
  next: () => void,
) => {
  if (pressedKey === 'ArrowLeft' && !isFirstElement) {
    prev();
  } else if (pressedKey === 'ArrowRight' && !isLastElement) {
    next();
  }
};

export default useKeyboardNavigation;
