import { DefaultTheme } from 'styled-components';

export default {
  wrapper: {
    maxWidth: '1600px',
  },
  device: {
    mobile: '@media (max-width: 400px)',
    tablet: '@media (max-width: 900px)',
    laptop: '@media (max-width: 1200px)',
    desktop: '@media (max-width: 1730px)',
    desktopL: '@media (max-width: 2560px)',
  },
  fontSize: {
    tiny: '1rem',
    xxSmall: '1.2rem',
    xSmall: '1.4rem',
    small: '1.6rem',
    medium: '1.8rem',
    large: '2rem',
    xLarge: '2.2rem',
    xxLarge: '2.4rem',
    huge: '2.6rem',
  },
  color: {
    primaryDark: '#14093f',
    primary: '#0e2356',
    primaryHover: '#112c6d',
    primaryMedium: '#566589',
    primaryLight: '#8691aa',
    primaryLighter: '#cfd3dd',
    primaryWhite: '#f3f4f7',
    primarySelected: '#613bea',
    primaryTransparent: '#0e2356b3',
    primaryTransparentMedium: '#56658980',
    primaryShadow: '#4227a480',
    secondary: '#00a3be',
    secondaryMedium: '#4cbfd1',
    secondaryLight: '#80d1de',
    secondaryLighter: '#ccedf2',
    secondaryWhite: '#f2fafc',
    warning: '#f1945e',
    error: '#ec5344',
    transparent: '#0000',
    shadow: '#0003',
    white: '#fff',
    black: '#000',
    blue: '#00a795',
    blueMedium: '#4cc1b5',
    blueLight: '#80d3ca',
    blueLighter: '#ccedea',
    blueWhite: '#f2fbfa',
    green: '#00ad00',
    greenMedium: '#4cc64c',
    greenLight: '#80d680',
    greenLighter: '#ccefcc',
    greenWhite: '#f2fbf2',
    yellow: '#ffe300',
    yellowMedium: '#ffeb4c',
    yellowLight: '#fff180',
    yellowLighter: '#fff9cc',
    yellowWhite: '#fffef2',
    orange: '#ff6200',
    orangeMedium: '#ff914c',
    orangeLight: '#ffb080',
    orangeLighter: '#ffe0cc',
    orangeWhite: '#fff7f2',
    red: '#ee0023',
    redMedium: '#f34c65',
    redLight: '#f68091',
    redLighter: '#fcccd3',
    redWhite: '#fef2f4',
    purple: '#9a78f0',
    purpleMedium: '#b8a0f4',
    purpleLight: '#ccbbf7',
    purpleLighter: '#ebe4fc',
    purpleWhite: '#faf8fe',
  },
} as DefaultTheme;
