import { createContext, useContext, ReactNode } from 'react';

export const useGenerateContext = <T extends object>(hook : () => T) : IContext<T> => {
  const context = createContext<T>(null);
  const provider = function Provider({ children } : IProps) {
    return <context.Provider value={hook()}>{ children }</context.Provider>;
  };
  return {
    Provider: provider,
    useContext: () => useContext<T>(context),
  };
};

export interface IProps {
  children: ReactNode
}

export interface IContext<T> {
  Provider: ({ children } : IProps) => JSX.Element,
  useContext: () => T
}
